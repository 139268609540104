import React from 'react';
import Layout from '../../../components/Shared/Layout';
import '../../../assets/css/services/web-development-old.css';
import AwardsSection from '../../../components/Services/WebDevelopment/Old/AwardsSection';
import EngagementModelsSection from '../../../components/Services/WebDevelopment/Old/EngagementModelsSection';
import withUTMParams from '../../../components/HOC/withUTMParams';

function WebDevelopmentService({location}) {
  return <div></div>;
}

export default withUTMParams(WebDevelopmentService);
